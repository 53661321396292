import logo from './logo.svg';
import './App.css';
import Navigationbar from './Navigationbar';
import Services from './Services';
import Portfolio from './Portfolio';
import About from './About';
import Team from './Team';
import Contact from './Contact'
import {BrowserRouter as Router,Routes,Route,useRoutes} from "react-router-dom";
import React from 'react'
import Footer from './Footer';


function App() {
  return (
    <div className="App">
    <Router>
      <Navigationbar/>
      <Routes>
        <Route path='/services' element={<Services/>}/>
        <Route path='/portfolio' element={<Portfolio/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/team' element={<Team/>}/>
        <Route path='/contact' element={<Contact/>}/>
      </Routes>
      </Router>
      <Footer/>
    </div>
  );
}

export default App;
